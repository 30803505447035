import { Link } from "gatsby"
import * as React from "react"
import logo from '../images/logo.svg'
import '../styles/main.css'

const IndexPage = () => {
	const age = new Date().getFullYear() - 1989

	return (
		<div className="wrapper">
			<div className="text-center logosHolder">
				<img src={logo} alt='' />
			</div>
			<div className="container">
				<div className="container__inner">
					<div className="aboutmeHolder">
						<div className="aboutme draggable">
							<div className="aboutme__header">
								<h3 className="">Wout Van Den Driessche</h3>
								<h5 className="text--upper text--subtle">UX/UI Designer<br />WEB / mobile developer</h5>
							</div>
							<div className="aboutme__text">
									<p>
										Currently I'm full-time employed at <a href="https://qargo.io">Qargo</a>, where I work mainly on the Front-end of the TMS software.
									</p>
									<p>
										Before I worked at <a href="https://eeve.com">Eeve</a>, where I designed and developed the <a href="https://apps.apple.com/be/app/eeve-com/id1609247182">app</a> (iOS &amp; Android) to control the robot.
									</p>
									<p>
										I started my career at <a href="https://www.massivemedia.eu/">Massive Media</a>. The makers of Netlog. Throughout those years I've acquired skills to become a Front-end &amp; App Developer.</p><p>My passion is to create things. Online and offline. I like to figure things out and connect with people, to turn ideas into projects.</p><p>Besides online creations, I founded <a href="https://www.instagram.com/dierbaar.eu/">Dierbaar</a>&nbsp;(discontinued) together with an interior designer, to make appealing cat furniture (featured on <a href="https://design-milk.com/tag/krab/">Design Milk</a>), a videobooth, light boxes...</p><p>Next to the cat business, I love bikes, <a href="https://www.thingiverse.com/wvddries/designs">3D Printing</a>, guitars, snowboarding...</p><p>Don't hesitate to <a href="mailto:vandendriessche@gmail.com">get in touch</a>.
									</p>
								</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default IndexPage
